<template>
  <modal-card :title="'Site notes'">
    <site-notes :site-id="siteId" />
  </modal-card>
</template>

<script>
export default {
  name: "SiteNotesModal",
  components: {
    "site-notes": () => import("@shared/sites/_siteNotes")
  },
  props: {
    siteId: {
      type: String,
      required: true
    }
  }
};
</script>
